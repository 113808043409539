import React, { useLayoutEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { motion, useTransform, useViewportScroll } from "framer-motion"

const About = ({ data }) => {
  const [elementTop, setElementTop] = useState(0);
  const elTitle = useRef()
  const page = data.markdownRemark
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, -1], {
    clamp: false
  });

  useLayoutEffect(() => {
    const element = elTitle.current;
    setElementTop(element.offsetTop);
  }, [elTitle]);


  return (
    <Layout ctx={'about'}>
      <SEO title={page.frontmatter.title} />
      <div className="page-jumbotron"  ref={elTitle}>
        <motion.h1 style={{ y }}>{page.frontmatter.title}</motion.h1>
      </div>
      <div className={'page-content'} dangerouslySetInnerHTML={{__html: page.html}}/>
    </Layout>
  )
}

About.propTypes = {

}

export default About

export const query = graphql`
    query About {
        markdownRemark(fileAbsolutePath: {regex: "/about.md/"}) {
            html
            frontmatter{
                title
            }
        }
    }
`